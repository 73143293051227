import { createApp } from 'vue'
import pinia from './stores'
import App from './App.vue'
import router from './router/index'
import cookies from 'js-cookie'
import axios from './api/request'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { setHandler } from './utils/set'
import { message, Modal } from 'ant-design-vue'
import { createConfirm } from '@/components/dialog/confirm/index.js'
import Im30Components from '@/components/im30/index'
import Im30Table from '@/components/im30Table/index'
import version from './plugins/version'
import sentry from './plugins/sentry'
import PermissionPlugin from './directives/permission'
import './static/iconfont/iconfont.css'
import './static/iconfont/iconfont.js'
import './assets/style/tailwind.css'
import './assets/style/index.less'

moment.locale('zh-cn')

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(version)
  .use(sentry)
  .use(Im30Components)
  .use(Im30Table)
  .use(PermissionPlugin)

// 全局变量
app.config.globalProperties.$cookies = cookies
app.config.globalProperties.$axios = axios
app.config.globalProperties.$moment = moment
app.config.globalProperties.$set = setHandler
app.config.globalProperties.$cfm = createConfirm
app.config.globalProperties.$message = message
app.config.globalProperties.$confirm = Modal.confirm
app.config.globalProperties.$info = Modal.info
app.config.globalProperties.$success = Modal.success
app.config.globalProperties.$error = Modal.error
app.config.globalProperties.$warning = Modal.warning

app.mount('#app')
