import imButton from './button/index.vue'
import imCheckbox from './checkbox/index.vue'
import ImUpload from '../common/Upload.vue'
import imSelect from './select/index.vue'
import type { App } from 'vue'

const components = [
  imButton,
  imCheckbox,
  ImUpload,
  imSelect
]

export default {
  install(app: App) {
    components.forEach(item => app.component(item.name, item))
  }
}
