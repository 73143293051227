import { PageEnum, RouterNameEnum } from '@/types/enums/pageEnum'
import type { RouteRecordRaw } from 'vue-router'

export const asyncProjectRoutesList: RouteRecordRaw[] = [
  {
    path: PageEnum.PROJECT_GOOD,
    name: RouterNameEnum.PROJECT_GOOD,
    meta: {
      icon: 'kopic-shangpin',
      title: '商品',
    },
    redirect: to => ({
      name: RouterNameEnum.PROJECT_CLASS,
      params: to.params,
      query: to.query,
    }),
    children: [
      {
        path: PageEnum.PROJECT_CLASS,
        name: RouterNameEnum.PROJECT_CLASS,
        meta: {
          layout: 'dashboardLayout',
          title: '虚拟物品',
        },
        component: () => import('@/views/projectManage/project/_projectId/class/index.vue'),
      },
      {
        path: PageEnum.PROJECT_CURRENCY,
        name: RouterNameEnum.PROJECT_CURRENCY,
        meta: {
          layout: 'dashboardLayout',
          title: '虚拟货币',
        },
        component: () => import('@/views/projectManage/project/_projectId/currency/index.vue'),
      },
      {
        path: PageEnum.PROJECT_LANGUAGE,
        name: RouterNameEnum.PROJECT_LANGUAGE,
        meta: {
          layout: 'dashboardLayout',
          title: `商品语言设置`,
        },
        component: () => import('@/views/projectManage/project/_projectId/storeLanguage/index.vue'),
      },
      {
        path: PageEnum.PROJECT_ACTIVITY,
        name: RouterNameEnum.PROJECT_ACTIVITY,
        meta: {
          title: `营销活动`,
          isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
        },
        children: [
          {
            path: PageEnum.PROJECT_ACTIVITY,
            name: PageEnum.PROJECT_ACTIVITY,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/index.vue'),
          },
          {
            path: PageEnum.PROJECT_ACTIVITY_DETAIL,
            name: RouterNameEnum.PROJECT_ACTIVITY_DETAIL,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/detail/_id/index.vue'),
          },
        ]
      },
      {
        path: PageEnum.PROJECT_LANG,
        name: RouterNameEnum.PROJECT_LANG,
        meta: {
          layout: 'dashboardLayout',
          title: '多语言管理',
        },
        component: () => import('@/views/projectManage/project/_projectId/lang/index.vue'),
      },
      {
        path: PageEnum.PROJECT_FACE_IMG,
        name: RouterNameEnum.PROJECT_FACE_IMG,
        meta: {
          layout: 'dashboardLayout',
          title: '拍脸图',
        },
        component: () => import('@/views/projectManage/project/_projectId/faceImg/index.vue'),
      },
    ]
  },
  {
    path: PageEnum.PROJECT_SITE_BUILD,
    name: RouterNameEnum.PROJECT_SITE_BUILD,
    meta: {
      icon: 'kopic-zaixianshangcheng3',
      isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
      title: '在线商城',
    },
    children: [
      {
        path: PageEnum.PROJECT_SITE_BUILD,
        name: PageEnum.PROJECT_SITE_BUILD,
        meta: {
          layout: 'dashboardLayout',
        },
        component: () => import('@/views/projectManage/project/_projectId/siteBuild/index.vue'),
      },
      {
        path: PageEnum.PROJECT_SITE_BUILD_EDITOR,
        name: RouterNameEnum.PROJECT_SITE_BUILD_EDITOR,
        component: () => import('@/views/projectManage/project/_projectId/siteBuild/editor/_domain.vue'),
      },
      {
        path: PageEnum.PROJECT_SITE_BUILD_PREVIEW,
        name: RouterNameEnum.PROJECT_SITE_BUILD_PREVIEW,
        component: () => import('@/views/projectManage/project/_projectId/siteBuild/preview/_domain/_lang.vue'),
      },
    ]
  },
  {
    path: PageEnum.PROJECT_USER_GROUP,
    name: RouterNameEnum.PROJECT_USER_GROUP,
    meta: {
      icon: 'kopic-yonghuqunti',
      layout: 'dashboardLayout',
      title: `用户群体`,
    },
    component: () => import('@/views/projectManage/project/_projectId/userGroup/index.vue'),
  },
  
  {
    path: PageEnum.PROJECT_PAYS,
    name: RouterNameEnum.PROJECT_PAYS,
    meta: {
      icon: 'kopic-zhifuzhongxin',
      title: '支付中心',
    },
    redirect: to => ({
      name: RouterNameEnum.PROJECT_PAYS_METHODS,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.PROJECT_PAYS_METHODS,
        name: RouterNameEnum.PROJECT_PAYS_METHODS,
        meta: {
          layout: 'dashboardLayout',
          title: '支付方式',
          actions_permissions: [{
            title: '支付厂商手续费',
            name: 'merchant:payment:method:rate',
          }],
        },
        component: () => import('@/views/projectManage/project/_projectId/pay/methods/index.vue'),
      },
      {
        path: PageEnum.PROJECT_PAYS_SORT,
        name: RouterNameEnum.PROJECT_PAYS_SORT,
        meta: {
          layout: 'dashboardLayout',
          title: '支付排序',
        },
        component: () => import('@/views/projectManage/project/_projectId/pay/sort/index.vue'),
      },
      {
        path: PageEnum.PROJECT_PAY_DISCOUNT,
        name: RouterNameEnum.PROJECT_PAY_DISCOUNT,
        meta: {
          layout: 'dashboardLayout',
          title: '接口配置',
        },
        component: () => import('@/views/projectManage/project/_projectId/pay/discount/index.vue'),
      },
    ]
  },
  {
    path: PageEnum.EXTERNAL,
    name: RouterNameEnum.EXTERNAL,
    meta: {
      icon: 'kopic-ic_shuangpingtaizhifuguanli',
      title: '渠道管理',
    },
    redirect: to => ({
      name: RouterNameEnum.EXTERNAL_CHANNEL,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.EXTERNAL_CHANNEL,
        name: RouterNameEnum.EXTERNAL_CHANNEL,
        meta: {
          title: '双平台支付管理',
          isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
        },
        children: [
          {
            path: '',
            name: '',
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/channelManagement/index.vue'),
          },
          {
            path: PageEnum.EXTERNAL_CHANNEL_DETAIL,
            name: RouterNameEnum.EXTERNAL_CHANNEL_DETAIL,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/channelManagement/detail.vue'),
          },
        ],
      },
      {
        path: PageEnum.EXTERNAL_CHANNEL_PRICE,
        name: RouterNameEnum.EXTERNAL_CHANNEL_PRICE,
        meta: {
          layout: 'dashboardLayout',
          title: '平台价格对比',
        },
        component: () => import('@/views/channelPrice/index.vue'),
      },
      {
        path: PageEnum.EXTERNAL_CHANNEL_IMPORT,
        name: RouterNameEnum.EXTERNAL_CHANNEL_IMPORT,
        meta: {
          layout: 'dashboardLayout',
          title: '批量导入记录',
        },
        component: () => import('@/views/channelImportRecord/index.vue'),
      },
    ]
  },
  {
    path: PageEnum.PROJECT_SETTING,
    name: RouterNameEnum.PROJECT_SETTING,
    meta: {
      icon: 'kopic-yingyongshezhi',
      title: `应用设置`,
    },
    redirect: to => ({
      name: RouterNameEnum.PROJECT_GENERAL_SETTING,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.PROJECT_GENERAL_SETTING,
        name: RouterNameEnum.PROJECT_GENERAL_SETTING,
        meta: {
          layout: 'dashboardLayout',
          title: `应用详情`,
        },
        component: () => import('@/views/projectManage/project/_projectId/generalSetting/index.vue'),
      },
      {
        path: PageEnum.PROJECT_WEBHOOKS_SETTING,
        name: RouterNameEnum.PROJECT_WEBHOOKS_SETTING,
        meta: {
          layout: 'dashboardLayout',
          title: "webhooks",
        },
        component: () => import('@/views/projectManage/project/_projectId/webhooksSetting/index.vue'),
      },
      {
        path: PageEnum.PROJECT_PRICE_TEMPLATE,
        name: RouterNameEnum.PROJECT_PRICE_TEMPLATE,
        meta: {
          layout: 'dashboardLayout',
          title: '定价模板',
        },
        component: () => import('@/views/projectManage/project/_projectId/pricetemplate/index.vue'),
      },
      {
        path: PageEnum.PROJECT_PRICE_UPDATE,
        name: RouterNameEnum.PROJECT_PRICE_UPDATE,
        meta: {
          layout: 'dashboardLayout',
          title: "自动更新价格",
        },
        component: () => import('@/views/projectManage/project/_projectId/priceUpdate/index.vue'),
      },
      {
        path: PageEnum.PROJECT_RISK_SETTING,
        name: RouterNameEnum.PROJECT_RISK_SETTING,
        meta: {
          layout: 'dashboardLayout',
          title: "风险设置",
        },
        component: () => import('@/views/projectManage/project/_projectId/riskSetting/index.vue'),
      },
      {
        path: PageEnum.PROJECT_RISK_MANAGER,
        name: RouterNameEnum.PROJECT_RISK_MANAGER,
        meta: {
          layout: 'dashboardLayout',
          title: "风险管理",
        },
        component: () => import('@/views/projectManage/project/_projectId/risk/index.vue'),
      },
      {
        path: PageEnum.PROJECT_BLACKLIST,
        name: RouterNameEnum.PROJECT_BLACKLIST,
        meta: {
          layout: 'dashboardLayout',
          title: "退款黑名单",
        },
        component: () => import('@/views/blacklistPage/index.vue'),
      },
      {
        path: PageEnum.REFUSE_BLACKLIST,
        name: RouterNameEnum.REFUSE_BLACKLIST,
        meta: {
          layout: 'dashboardLayout',
          title: "拒付黑名单",
        },
        component: () => import('@/views/blacklistPage/refuse.vue'),
      },
    ]
  },
  {
    path: PageEnum.PROJECT_INTEGRAL,
    name: RouterNameEnum.PROJECT_INTEGRAL,
    meta: {
      icon: 'kopic-jifenshangcheng',
      title: '积分商城',
    },
    redirect: to => ({
      name: RouterNameEnum.PROJECT_INTEGRAL_GOODS,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.PROJECT_INTEGRAL_GOODS,
        name: RouterNameEnum.PROJECT_INTEGRAL_GOODS,
        meta: {
          layout: 'dashboardLayout',
          title: '积分商品',
        },
        component: () => import('@/views/projectManage/project/_projectId/integral/goods.vue'),
      },
      {
        path: PageEnum.PROJECT_INTEGRAL_TASKS,
        name: RouterNameEnum.PROJECT_INTEGRAL_TASKS,
        meta: {
          layout: 'dashboardLayout',
          title: '积分任务',
        },
        component: () => import('@/views/projectManage/project/_projectId/integral/tasks.vue'),
      },
    ]
  },
  {
    path: PageEnum.VIP_STORE,
    name: RouterNameEnum.VIP_STORE,
    meta: {
      icon: 'kopic-huiyuanzhongxin',
      title: '会员中心',
    },
    redirect: to => ({
      name: RouterNameEnum.VIP_STORE_SETTING,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.VIP_STORE_SETTING,
        name: RouterNameEnum.VIP_STORE_SETTING,
        meta: {
          layout: 'dashboardLayout',
          title: '权益配置',
        },
        component: () => import('@/views/projectManage/project/_projectId/vipStoreSetting/index.vue'),
      },
      {
        path: PageEnum.VIP_VARIABLES_SETTING,
        name: RouterNameEnum.VIP_VARIABLES_SETTING,
        meta: {
          layout: 'dashboardLayout',
          title: '参数配置',
        },
        component: () => import('@/views/projectManage/project/_projectId/vipVariablesSetting/index.vue'),
      },
    ]
  },
  {
    path: PageEnum.PROJECT_WELFARE,
    name: RouterNameEnum.PROJECT_WELFARE,
    meta: {
      icon: 'kopic-fulizhongxin',
      layout: 'dashboardLayout',
      title: '福利中心',
    },
    component: () => import('@/views/projectManage/project/_projectId/welfare/index.vue'),
  },
  {
    path: PageEnum.PROMOTION_SHARE,
    name: RouterNameEnum.PROMOTION_SHARE,
    meta: {
      icon: 'kopic-fenxianglaxin',
      layout: 'dashboardLayout',
      title: '分享拉新',
    },
    component: () => import('@/views/projectManage/project/_projectId/promoter/index.vue'),
  },
  {
    path: PageEnum.AUTO_LOGIN_LINK,
    name: RouterNameEnum.AUTO_LOGIN_LINK,
    meta: {
      icon: 'kopic-zidongdenglulianjie',
      title: '自动登录链接',
      isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
    },
    redirect: to => ({
      name: RouterNameEnum.AUTO_LOGIN_LINK_INDEX,
      params: to.params,
      query: to.query,
    }),
    children: [
      {
        path: PageEnum.AUTO_LOGIN_LINK_INDEX,
        name: RouterNameEnum.AUTO_LOGIN_LINK_INDEX,
        meta: {
          layout: 'dashboardLayout',
        },
        component: () => import('@/views/autoLoginLink/index.vue'),
      },
      {
        path: PageEnum.AUTO_LOGIN_LINK_DETAIL,
        name: RouterNameEnum.AUTO_LOGIN_LINK_DETAIL,
        meta: {
          layout: 'dashboardLayout',
        },
        component: () => import('@/views/autoLoginLink/linkDetail.vue'),
      },
    ],
  },
  {
    path: PageEnum.MSG_PUSH,
    name: RouterNameEnum.MSG_PUSH,
    meta: {
      icon: 'kopic-kehuduantuisong',
      layout: 'dashboardLayout',
      title: '营销信息推送',
    },
    component: () => import('@/views/msgPush/index.vue'),
  },
]
