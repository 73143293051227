<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ImCheckbox",
  
})
</script>
<script setup name="ImCheckbox" lang="ts">
import { computed } from 'vue';

interface Props {
  shape: string
}
const props = withDefaults(defineProps<Props>(), {
  shape: '',
})
const getClass = computed(() => {
  const className: string[] = []
  if (props.shape === 'circle') {
    className.push('circle')
  }
  return className
})
</script>

<template>
  <a-checkbox v-bind="{ ...$props, ...$attrs }" :class="getClass">
    <slot />
  </a-checkbox>
</template>

<style lang="less">
.ant-checkbox-wrapper {
  &.circle {
    .ant-checkbox-inner {
      border-radius: 100%;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
