import request from '@/api/request'
import type { OperationLogParams } from '@/types/interface/operationRecord'

/**
 * 退款黑名单-编辑配置
 *
 * @param data 
 * @returns 
 */
export const editBlacklistApi = (data: any, params: OperationLogParams) => {
  return request.post('/api/v2/merchant/customer-group/risk-refund-update', data, { params })
}

/**
 * 退款黑名单-批量添加
 *
 * @param data 
 * @returns 
 */
export const batchCreateBlacklistApi = (data: any, params: OperationLogParams) => {
  return request.post('/api/v2/merchant/customer-group/risk-refund-items-add', data, { params })
}

/**
 * 退款黑名单-用户批量删除
 *
 * @param data 
 * @returns 
 */
export const batchDeleteBlacklistApi = (data: any, params: OperationLogParams) => {
  return request.post('/api/v2/merchant/customer-group/risk-refund-items-delete', data, { params })
}

/**
 * 用户群体详情
 *
 * @param params 
 * @returns 
 */
export const getCustomerGroupInfoApi = (params: any) => {
  return request.get('/api/v2/merchant/customer-group/info', { params })
}

/**
 * 退款黑名单-获取列表
 *
 * @param params 
 * @returns 
 */
export const getBlacklistApi = (params: any) => {
  return request.get('/api/v2/merchant/customer-group/risk-refund-items', { params })
}

/**
 * 退款黑名单-操作日志
 *
 * @param params 
 * @returns 
 */
export const getBlacklistLogApi = (params: any) => {
  return request.get('/api/v2/merchant/customer-group/risk-refund-log', { params })
}

/**
 * 退款黑名单-导出
 *
 * @param params 
 * @returns 
 */
export const exportBlacklistApi = (params: any) => request({
  url: '/api/v2/merchant/customer-group/risk-refund-items-export',
  method: 'get',
  params,
  responseType: 'blob',
})
