<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ImUpload",
  
})
</script>
<script setup name="ImUpload" lang="ts">
import { upload as uploadApi } from '@/api/common/index'
import KopIcon from '@/components/common/KopIcon.vue'
import { computed, h, ref } from 'vue';
import { message, Modal } from 'ant-design-vue'

interface Props {
  modelValue: string
  /**
   * 最大图片分辨率宽度
   */
  maxWidth?: number
  /**
   * 最大图片分辨率高度
   */
  maxHeight?: number
  /**
   * 最大图片大小
   */
  maxSize?: number
  /**
   * 是否显示提示
   */
  showTips?: boolean
  /**
   * 是否允许删除
   */
  allowClear?: boolean
  /**
   * 上传提示
   */
   uploadText?: string
}

interface Emit {
  (e: 'update:modelValue', val: string | null): void
  (e: 'input', val: string | null): void
  (e: 'change', val: string | null): void
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  maxWidth: 80,
  maxHeight: 80,
  maxSize: 1,
  showTips: true,
  allowClear: false,
})
const emit = defineEmits<Emit>()

const uploading = ref(false)
const isShowClear = ref(false)
const currentValue = computed({
  get() {
    return props.modelValue
  },
  set(val: string | null) {
    emit('update:modelValue', val)
    emit('input', val)
    emit('change', val)
  }
})
const isVideo = computed(()=>{
  return currentValue.value?.endsWith('.mp4')
})

const beforeUpload = async (file: any) => {
  console.log(file.size)
  const fileTypeAllow = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'video/mp4'
  if (!fileTypeAllow) {
    message.error('您仅能上传jpg或者png文件或mp4文件!')
    return false
  }
  if(file.type === 'video/mp4'){
    const isSizeOver = file.size / 1024 / 1024 > 10
    if (isSizeOver) {
      message.error(`视频最大不能超过 10MB!`)
      return false
    }else{
      return true
    }
  }
  const isSize = await new Promise((resolve, reject) => {
    const width = props.maxWidth || 80
    const height = props.maxHeight || 80
    const _URL = window.URL || window.webkitURL
    const image = new Image()
    image.onload = function () {
      const valid = image.width >= width && image.height >= height
      // image.width / image.height === 1
      // eslint-disable-next-line prefer-promise-reject-errors
      valid ? resolve(valid) : reject()
    }
    image.src = _URL.createObjectURL(file)
  }).then(
    () => {
      return file
    },
    () => {
      message.error(`上传的图片最小分辨率不低于${props.maxWidth}*${props.maxHeight}`)
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(false)
    }
  )
  if (!isSize) {
    return false
  }
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'video/mp4'
  if (!isJpgOrPng) {
    message.error('您仅能上传jpg或者png文件或mp4文件!')
    return false
  }
  const isLt1M = file.size / 1024 / 1024 <= props.maxSize
  if (!isLt1M) {
    message.error(`图片最大不能超过 ${props.maxSize}MB!`)
    return isLt1M
  }
  return isJpgOrPng && isLt1M && isSize
}
const customRequest = async (options: any) => {
  uploading.value = true
  const formData = new FormData()
  const c = await beforeUpload(options.file)
  console.log(options)
  if (!c) {
    uploading.value = false
  } else {
    formData.append('file', options.file)
    formData.append("bucket", 'kop-site-builder');
    formData.append("url_pre_fix", 'site-builder');
    formData.append("dir", 'store');
    uploadApi(formData)
      .then((res: any) => {
        options.onSuccess(res)
        uploading.value = false
      })
      .catch(() => {
        options.onError()
        uploading.value = false
      })
  }
}
const handleChange = (info: any) => {
  if (info?.file?.status === 'uploading') {
    return uploading.value = true
  }
  if (info?.file?.status === 'done') {
    uploading.value = false
    currentValue.value = info?.file?.response?.url
  }
}
const handleClear = () => {
  if (props.allowClear) {
    currentValue.value = null
  }
}

const deleteIcon = (e: Event) => {
  e.preventDefault()
  e.stopPropagation();
  if (!props.allowClear) {
    return
  }
  Modal.confirm({
    title: '确认删除',
    content: '删除后不可恢复',
    icon: h(KopIcon, {
      type: "kopic-jingshi",
      style: { color: '#FF6055' }
    }),
    class: 'kop_modal_confirm',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    width: '480px',
    centered: true,
    closable: false,
    keyboard: false,
    maskClosable: false,
    zIndex: 2050,
    onOk: () => {
      handleClear()
    },
  });
}

const videoRef = ref()
const videoWrapRef = ref()
const adjustVideoSize= ()=>{
  if(videoRef.value && videoWrapRef.value){
    const videoRatio = videoRef.value.videoWidth / videoRef.value.videoHeight;
    console.log(videoRatio)
    const containerRatio = videoWrapRef.value.offsetWidth / videoWrapRef.value.offsetHeight;

    if (videoRatio > containerRatio) {
      // 视频的宽度比高度大，填满宽度
      videoRef.value.style.width = '100%';
      videoRef.value.style.height = 'auto';
    } else {
      // 视频的高度比宽度大，填满高度
      videoRef.value.style.width = 'auto';
      videoRef.value.style.height = '100%';
    }
  }
}
</script>

<template>
  <a-upload
    v-bind="$attrs"
    :show-upload-list="false"
    :custom-request="customRequest"
    list-type="picture-card"
    class="kop_upload"
    @change="handleChange"
  >
    <div class="relative flex items-center justify-center w-full h-full upload-content">
      <!-- 图片显示区域 -->
      <div
        v-if="currentValue && !isVideo"
        class="relative w-full h-full bg-center bg-no-repeat bg-contain"
        :style="{backgroundImage: `url(${currentValue})`}"
        alt="avatar"
        @mouseover="isShowClear = true"
        @mouseleave="isShowClear = false"
      >
        <div v-if="allowClear && isShowClear" class="delete-wrapper" @click="deleteIcon">
          <kop-icon type="kopic-shanchu" />
        </div>
      </div>
      <!-- 视频显示区域 -->
      <div ref="videoWrapRef" class="upload-video" v-if="currentValue && isVideo && !uploading"  @mouseover="isShowClear = true"
           @mouseleave="isShowClear = false">
        <video ref="videoRef" @loadedmetadata="adjustVideoSize">
          <source :src="currentValue" type="video/mp4">
          你的浏览器不支持 HTML5 视频标签。
        </video>
        <div v-if="allowClear && isShowClear" class="delete-wrapper" @click="deleteIcon">
          <kop-icon type="kopic-shanchu" />
        </div>
      </div>
      <!-- 上传显示区域 -->
      <div v-if="!currentValue" class="flex flex-col items-center justify-center up_tips">
        <kop-icon type="kopic-shangchuan" class="text-16 mb-[2px]" />
        <span class="text-14 leading-[22px]">{{ uploadText || '上传' }}</span>
      </div>

      <!-- 上传加载区域 -->
      <div
        v-if="uploading"
        class="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center upload-spin"
      >
        <a-spin :spinning="uploading"></a-spin>
      </div>
    </div>
  </a-upload>
  <div v-if="showTips" class="upload_tips_desc">建议最小尺寸 {{maxWidth}}*{{maxHeight}}，图片不能超过{{maxSize}}M</div>
</template>

<style lang="less" scoped>
.kop_upload {
  :deep(.ant-upload-select) {
    width: 80px;
    height: 80px;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 8px;
    color: #adaeb5;
    border: 1px dashed #d0cfcf;
    background: rgba(248, 248, 250, 0.9);

    .up_tips {
      font-family: PingFangSC-Regular;
      color: #adaeb5;
    }

    .upload-spin {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.ant-upload-disabled {
      &:hover {
        color: #adaeb5;
        border: 1px dashed #adaeb5;

        .up_tips {
          color: #adaeb5;
        }
      }
    }

    &:hover {
      color: #3d46f5;
      border: 1px dashed #3d46f5;

      .up_tips {
        color: #3d46f5;
      }
    }
  }
}

.upload_tips_desc {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  line-height: 22px;
  color: #adaeb5;
}

.delete-wrapper {
  @apply flex items-center justify-center absolute w-6 h-6 rounded-md;
  top: 2px;
  right: 2px;
  background: rgba(24, 23, 28, 0.36);
  color: #ffffff;

  &:hover {
    background-color: #ff6055;
  }
}
.upload-video {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>