import request from '@/api/request'
import type { OperationLogParams } from '@/types/interface/operationRecord'
import { message } from 'ant-design-vue'

/**
 * 应用列表
 *
 * @param params
 * @returns
 */
export const getProjects = (params: any) =>
  request.get('/api/v2/project/list', { params }).catch((err: any) => {
    err && message.error(err?.message)
  })

/**
 * 创建应用
 *
 * @param data
 * @returns
 */
export const postProjects = (data: any, params: OperationLogParams) =>
  request.post('/api/v2/project/create', data, { params })

/**
 * 删除应用
 *
 * @param params
 * @returns
 */
export const deleteProject = (data: any, params: OperationLogParams) =>
  request.post('/api/v2/project/delete', data, { params })

/**
 * 修改应用
 *
 * @param data
 * @returns
 */
export const patchProject = (data: any, params: OperationLogParams) =>
  request.post('/api/v2/project/edit', data, { params })

/**
 * 应用详情
 *
 * @param params
 * @returns
 */
export const getProject = (params: any) =>
  request.get('/api/v2/project/info', { params })

/**
 * 刷新应用密钥
 *
 * @param data
 * @returns
 */
export const patchWebhookSecretKey = (data: any, params: OperationLogParams) =>
  request.post('/api/v2/project/refresh-webhook', data, { params })

export const getLanguage = (params: any) =>
  request.get('/api/v2/base/language/all', { params })
