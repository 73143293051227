import request from '@/api/request'
import type { BasePagination } from '@/types/interface/base'
import type { LoginRequest, RegisterUserRequest, ResetPasswordRequest, VerifyCodeRequest } from '@/types/interface/userInterface'
import type { UserEditRequest } from '@/types/type/userType'

/**
 * 获取 aes加密 key和标识
 *
 * @returns
 */
export const getAesKey = () => request.get('/api/v2/encrypt/aes')

/**
 * 获取当前用户的商户列表
 *
 * @returns
 */
export const getMyMerchantList = (params: BasePagination) => request.get('/api/v2/user/merchant/list', { params })

/**
 * 获取验证码
 *
 * @param data
 * @returns
 */
export const sendVerifyCode = (data: VerifyCodeRequest) => request.post('/api/v2/send-code', data)

/**
 * 用户注册
 *
 * @param data
 * @returns
 */
export const registerUser = (data: RegisterUserRequest) => request.post('/api/v2/register', data)

/**
 * 用户登录
 *
 * @param data
 * @returns
 */
export const login = (data: LoginRequest) => request.post('/api/v2/login', data)

/**
 * 获取用户信息
 *
 * @param token
 * @returns
 */
export const getUserInfo = (token?: string) => {
  const config = token
    ? { Authorization: token }
    : {}
  return request({
    method: 'get',
    url: '/api/v2/user/info',
    ...config,
  })
}

/**
 * 重置密码
 *
 * @param data
 * @returns
 */
export const resetPassword = (data: ResetPasswordRequest) => request.post('/api/v2/reset-password', data)

/**
 * 修改用户信息
 *
 * @param data 
 * @returns 
 */
export const editUser = (data: UserEditRequest) => request.post('/api/v2/user/edit', data)


/**
 * 获取SSO登录地址
 */
export const getSsoUrl = () => request.get('/api/v2/merchant/sso/config')


/**
 * 获取SSO登录token
 *
 * @param data 
 * @returns
 */
export const getSsoToken = (data: any) => request.post('/api/v2/merchant/sso/login', data)
