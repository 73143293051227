import { createRouter, createWebHistory } from 'vue-router'
import { routesList as routes } from './list/index';
import { TokenName } from '@/config/cookieKeys';
import { PageEnum } from '@/types/enums/pageEnum';
import { UserStore } from '@/stores/modules/user';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { routesWhiteList } from './list/index';
import cookies from 'js-cookie'
import { ProjectStore } from '@/stores/modules/project';
import { getProject } from '@/api/project';
import { message } from 'ant-design-vue';
import { loginOut } from '@/utils/utils';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useAuthStore } from '@/stores/modules/authStore';
import { getSsoToken } from '@/api/user/index'

NProgress.configure({ showSpinner: false });

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  NProgress.start()
  const toPath = to.path

  // 判断是否不需要登录
  if (routesWhiteList.includes(toPath)) {
    return next()
  }

  // 获取token
  const cookiesToken = cookies.get(TokenName)

  // 有token代表已经登录过了
  if (cookiesToken) {  
    const userStore = UserStore()
    // 确保 store中存在 user信息
    if (!userStore?.user?.user_no) {
      try {
        const [userInfo] = await userStore.getUserInfoMenu() // 防止有人删掉了cookie
        if (!userInfo) {
          loginOut()
          next(PageEnum.BASE_LOGIN)
        } else {
          userStore.allPermissionRouter.forEach((r) => {
            router.addRoute(r)
          })
          router.addRoute({
            path: '/:pathMatch(.*)*',
            redirect: PageEnum.ERROR_PAGE,
          })
          return next({ ...to, replace: true })
        }
      } catch (error: any) {
        message.error(error?.message || '获取用户详情失败')
        console.error(error)
      }
    }

    // 确保应用下的页面 store中存在 project信息
    if (toPath.includes('projectManage/project/')) {
      const projectId = to?.params?.projectId
      const projectSore = ProjectStore()
      if (!projectId || typeof projectId !== 'string') {
        return next(PageEnum.ERROR_PAGE)
      }
      if (!Object.keys(projectSore.info).length || projectSore?.info?.id !== +projectId) {
        try {
          const authStore = useAuthStore()
          const menu_no = to.name
            ? await authStore.getMenuNoByName(to.name as string)
            : ''
          const project = await getProject({
            project_id: projectId,
            menu_no
          })
          projectSore.SET_INFO(project)
          projectSore.SET_PROJECT_ID(projectId)
          projectSore.SET_PROJECT_NO(project.project_no)
        } catch (error: any) {
          message.error(error?.message || '获取应用详情失败')
          console.error(error)
        }
      }
    }
    return next()
  } else {
    // 无token 查看是否是sso登录
    const { code, state } = to.query
    if(code) {
      try {
        // 获取sso token
        await getSsoToken({ code, state })
        return next(toPath)
      }
      catch (error: any) {
        message.error(error?.message || '登录失败')
        return next(PageEnum.BASE_LOGIN)
      }
    } else {
      return next(PageEnum.BASE_LOGIN)
    }
  }
});

router.afterEach(() => {
  NProgress.done()
});

export default router;
