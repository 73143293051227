<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ImButton",
  
})
</script>
<template>
  <a-button
    v-bind="{...$attrs, ...props}"
    :class="getClass"
    :style="getStyle"
    class="rounded-lg min-w-80"
  >
    <im-icon
      v-if="icon"
      class="mr-2"
      :icon="icon"
    />
    <slot />
  </a-button>
</template>

<script setup lang="ts" name="ImButton">
import ImIcon from "@/components/common/ImIcon.vue";
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  width: {
    type: [Number, String],
    default: ''
  },
  plain: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'default'
  },
  flex: {
    type: Boolean,
    default: false
  },
  shadow: {
    type: Boolean,
    default: false
  }
})

const getStyle = computed(() => {
  const style: any = {}
  if (typeof props.width === 'number') {
    style.width = props.width + 'px'
  } else if (typeof props.width === 'string' && props.width) {
    style.width = props.width.replace('px', '') + 'px'
  }
  return style
})

const getClass = computed(() => {
  const prefix = 'im30-button-'
  const classNames = [`${props.type}`, 'im30-button']
  if (props.shadow) classNames.push('shadow')
  if (props.plain) classNames.push('plain')
  if (props.flex) classNames.push('flex')
  if (props.size) classNames.push(prefix + props.size)
  if (props.type) classNames.push(prefix + props.type)
  return classNames
})
</script>

<style lang="less">
.button-hover-mixin(@color: white, @bColor) {
  background-color: @bColor;
  border-color: @bColor;
  color: @color;
}

.im30-button {
  &.ant-btn {
    box-sizing: border-box;
    padding: 7px 16px;
    color: @text-color-secondary;
    border-color: @line-color;

    &:hover,
    &:focus {
      color: @primary-color;
      border-color: @primary-color;
    }
  }

  &.ant-btn-link {
    border-color: transparent;
    color: @primary-color;

    &:hover {
      background: @gary-color;
      color: @primary-color-alpha-8;
    }

    &:focus,
    &:active {
      background: @line-color;
      color: @primary-color-alpha-8;
    }

    &:hover,
    &:focus,
    &:active,
    .active {
      border-color: transparent;
    }
  }

  &.im30-button {
    &-large {
      font-size: 16px;
      padding: 10px 16px;
      height: 44px;

      .icon {
        margin-right: 9px;
      }
    }

    &-small {
      padding: 5px 16px;
      height: 32px;
      font-size: 14px;
    }

    &-mini {
      padding: 0px 8px;
      height: 24px;
      font-size: 12px;
    }
  }

  &.flex {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  &.success {
    background-color: @success-color;
    border-color: @success-color;
    color: white;

    &:hover {
      .button-hover-mixin(white, @success-hover-color);
    }

    &:focus {
      .button-hover-mixin(white, @success-click-color);
    }
  }

  &.ant-btn-primary {
    border-color: @primary-color;
    color: #ffffff;

    &.shadow {
      box-shadow: 0 4px 5px rgba(92, 111, 254, 0.4);
    }

    &:hover {
      background: rgba(90, 71, 238, 0.8);
      color: #ffffff;
    }

    &:focus,
    &:active {
      .button-hover-mixin(white, @click-color);
    }

    &.disabled,
    &[disabled] {

      &,
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: #ffffff;
        background: #dedafc;
        text-shadow: none;
        border-color: #dedafc;
        box-shadow: none;
      }
    }

    &.plain {
      background-color: @primary-color-light;
      color: @primary-color;
      border: 1px solid @primary-color;

      &:hover {
        background-color: @primary-color;
        color: #ffffff;
      }
    }

    &.ant-btn-background-ghost {
      background-color: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;

      &:hover {
        border-color: #8773fa;
        color: #8773fa;
      }
    }
  }
}

.min-w-80 {
  min-width: 80px;
}
</style>
