// @ts-nocheck
import axios from 'axios'
import { notification, Alert, Button } from 'ant-design-vue'
import { on, off } from '@/utils/dom'
import { h } from 'vue'

interface GetDataRes {
  data: {
    number: number,
    buildTime: string,
    log: string[]
  }
}

const checkVersion = async () => {
  try {
    if (!document.hidden) {
      const versionUrl = '/version.json?' + new Date().getTime()
      const local = localStorage.getItem('version')
      const version = await axios.get<GetDataRes>(versionUrl)
      if (version.data && (local !== version.data.number?.toString?.())) {
        notification.open({
          message: `有新版本可用(build:${version.data.buildTime})`,
          key: 'update',
          duration: 0,
          description() {
            const handleClose = () => {
              localStorage.setItem('version', version.data.number?.toString?.())
              notification.close('update')
            }
            const handleUpdate = () => {
              localStorage.setItem('version', version.data.number?.toString?.())
              window.location.reload()
            }
            return h('div', {
              class: 'version-notify'
            }, [
              h(Alert, {
                type: 'info',
                'show-icon': true,
                message: '程序检测到新版本，建议选择立即更新',
                description: '更新将会刷新页面，如果您要优先完成剩余操作，请选择稍后更新',
              }),
              h('div', {
                class: 'mt-[12px]',
              }, '最近的更新：'),
              version?.data?.log?.map((item: string) => h('div', {
                class: 'mt-[5px] text-color-8e',
              }, item)),
              h('div', {
                class: 'handle-box mt-24px text-center flex justify-center',
              }, [
                h(Button, {
                  type: 'primary',
                  style: {
                    height: '32px',
                  },
                  onClick: handleUpdate
                }, () => '立即更新'),
                h(Button, {
                  class: 'ml-24px',
                  style: {
                    height: '32px',
                  },
                  onClick: handleClose
                }, () => '稍后更新'),
              ])
            ])
          }
        })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export default {
  install: () => {
    if (import.meta.env.VITE_USER_NODE_ENV === 'development') {
      return
    }
    (async function () {
      const versionUrl = '/version.json?' + new Date().getTime()
      const version = await axios.get<GetDataRes>(versionUrl)
      if (version.data) {
        localStorage.setItem('version', version.data.number?.toString?.())
      }
    })()
    off(document, 'visibilitychange', checkVersion)
    on(document, 'visibilitychange', checkVersion)
  }
}
