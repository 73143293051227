import { isPre, isRelease } from './index'
export const TokenName = process.env.NODE_ENV !== 'production'
  ? 'local-im30-pay-token'
  : isRelease
    ? 'im30-pay-token'
    : isPre
      ? 'preview-im30-pay-token'
      : 'test-im30-pay-token'
export const CookieDomain = import.meta.env.VITE_TOKEN_DOMAIN
export const LangName = process.env.NODE_ENV !== 'production' ? 'local-pay-lang' : 'im30-pay-lang'
export const isHttps = !!(isPre || isRelease) // 域名是否为https，设置cookie时有用
export const SandboxModeKey = 'is-sandbox'
