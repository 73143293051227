export enum HttpCodeEnum {
  ERROR = 0,
  TYPE = 'success',
  INFO_SUCCESS = 1, // 成功
  ERROR_UNKNOWN = -1, // 未知错误
  ERROR_SIGNATURE_EXPIRED = 10016, // 签名已过期，请重新登录
  ERROR_PERMISSION_DENIED = 10006, // 权限不足
  ERROR_API_PERMISSION_DENIED = 10005, // 应用未授权或已过期
  ERROR_NO_LOGIN = 10012, // 请先登录
  NOTPERMISSON = 401,
  ERROR_VALIDATION = 10004, // 校验错误
  ERROR_DATA = 10000, // 数据错误
  ERROR_TIMEOUT = 10001, // 超时
  ERROR_FORM = 10002, // 表单错误
  ERROR_PARAMETER = 10003, // 参数错误
  ERROR_ACCOUNT_NO_EXIST = 10007, // 账号不存在
  ERROR_PASSWORD = 10008, // 密码错误
  ERROR_VERIFY = 10009, // 验证码错误/过期
  ERROR_RE_VERIFY = 10010, // 已经校验过验证码，无需校验
  ERROR_NO_VERIFY = 10011, // 请先校验验证码
  ERROR_NO_GALLERY = 10013, // 请先初始化用户画像
  ERROR_NO_ACTIVE = 10014, // 请先激活账户
  ERROR_DATA_NOT_EXISTS = 10015, // 数据不存在
  ERROR_NETWORK = 10017, // 服务器网络异常
  ERROR_URL = 10018, // url异常
  ERROR_AGREEMENT_LICENSE_EXISTS = 10019,
  ERROR_COUNTRY = 10020,
  ERROR_USERNAME_PASSWORD = 10021, // 用户名或密码错误
  ERROR_USER_NOT_EXISTS = 10022, // 用户不存在
  NO_NEED_UPDATE = 20001, // 无需更新
  ERROR_MERCHANT_EXISTS = 30001, // 用户已绑定商户
  ERR_WEBHOOK_STATUS = 40001, // 项目回调url状态未打开
  ERR_WEBHOOK_URL = 40002, // 该登录管理器未设置回调url
  ERR_WEBHOOK_URL_REQUIRED = 40003, // 该登录管理器设置了多个回调url，必须传入login_url参数
  ERR_WEBHOOK_VALUE = 40004, // login_url参数不在该登录管理器设置的多个回调url中
  ERR_MERCHANT_STATUS = 40005, // 商户未激活
  ERR_MERCHANT_SERVICE = 40006, // 商户服务异常
}
