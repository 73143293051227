<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "KopButton",
  
})
</script>
<script setup name="KopButton" lang="ts">
/**
 * default 默认按钮
 * primary 主按钮
 * plain 朴素按钮
 * ghost 幽灵按钮
 * text 文字按钮
 * link 链接按钮
 * danger 危险按钮
 * danger-ghost 危险按钮 幽灵状态
 * danger-text 危险按钮 文字状态
 * */
// enum KopButtonType {
//   default = 'default',
//   primary = 'primary',
//   plain = 'plain',
//   ghost = 'ghost',
//   text = 'text',
//   link = 'link',
//   danger = 'danger',
//   'danger-ghost ' = 'danger-ghost',
//   'danger-text' = 'danger-text',
// }
interface Props {
  type?: string
  kopicIcon?: string
  iconClass?: string
  iconPlacement?: string
}

withDefaults(defineProps<Props>(), {
  type: 'default',
  kopicIcon: '',
  iconClass: '',
  iconPlacement: 'left'
})
</script>

<template>
  <a-button v-bind="$attrs" :class="['kop_button', `ant-btn-${type}`]">
    <template #icon>
      <kop-icon v-if="iconPlacement === 'left' && kopicIcon" :type="kopicIcon" :class="['kop_btn_icon', iconClass]" />
    </template>
    <slot />
    <kop-icon v-if="iconPlacement === 'right' && kopicIcon" :type="kopicIcon" :class="['kop_btn_icon_right', iconClass]" />
  </a-button>
</template>
