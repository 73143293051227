import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from '@/router/index'
import type { App } from "vue";

export default {
  install(app: App) {
    if (import.meta.env.VITE_USER_NODE_ENV === 'production') {
      Sentry.init({
        app,
        dsn: "https://2eaf7ea501e74162ae941283fb664376@log.koppay.net//13",
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        trackComponents: true,
        logErrors: true,
      });

      app.config.globalProperties.$sentry = Sentry
    }
  }
}