import { UserStore } from "@/stores/modules/user"
import { storeToRefs } from "pinia"
import { computed } from "vue"

export function checkActionPermission(el: any, value: string[]) {
  const userStore = UserStore()
  const { user } = storeToRefs(userStore)
  const permissions = computed(() => user.value?.permissions)

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionKeys = value

      const hasPermission = permissions.value?.some?.(per => {
        return permissionKeys.includes(per)
      })
      if (!hasPermission) {
        el?.parentNode?.removeChild?.(el)
      }
      return hasPermission
    }
    return true;
  } else {
    throw new Error(`need permission keys! Like v-permission="['merchant:statement:export']"`)
  }
}

// v-permission="['merchant:statement:export']"
export default {
  mounted(el: any, binding: any) {
    checkActionPermission(el, binding.value)
  },
  updated(el: any, binding: any) {
    checkActionPermission(el, binding.value)
  }
}