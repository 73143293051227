import { LangName } from '@/config/cookieKeys'
import { defineStore } from "pinia"
import cookies from 'js-cookie'
import { getProject } from '@/api/project/index'
import { getBlacklistApi } from '@/api/blacklist';
import { RouterNameEnum } from '@/types/enums/pageEnum'
import { useOperatorLog } from '@/hooks/useOperatorLog'
import {useRoute} from 'vue-router'
export const ProjectStore = defineStore('project', {
  state: () => ({
    info: {} as any,
    projectId: '',
    projectNo: '',
    customerGroupId: ''
  }),

  getters: {
    storeLang(state) {
      const { default_language, languages } = state.info;

      // zh 需转成 cn
      // eslint-disable-next-line
      const lang = cookies.get(LangName) === 'zh' ? 'cn' : cookies.get(LangName);
      // 网站语言包括在商品语言中，商品跟随网站语言；反之商品显示商品默认语言
      if (languages && languages.includes(lang)) {
        return lang
      } else {
        // eslint-disable-next-line
        return default_language;
      }
    }
  },

  actions: {
    SET_INFO(info: any) {
      this.info = info
    },
    SET_PROJECT_ID(id: string) {
      this.projectId = id
    },
    SET_PROJECT_NO(id: string) {
      this.projectNo = id
    },
    updateProject(payload: any) {
      getProject({
        project_id: payload.id
      }).then((res: any) => {
        this.SET_INFO(res)
      })
    },

    async getCustomerGroupId() {
      try {
        const route = useRoute()
        const {
          getOperatorLogQueryParams,
        } = useOperatorLog(RouterNameEnum.PROJECT_BLACKLIST)
        if (this.customerGroupId) {
          return this.customerGroupId
        }
        const logParams = await getOperatorLogQueryParams()
        const { customer_group_id } = await getBlacklistApi({
          project_id: this.projectId,
          page_no: 1,
          page_size: 1,
          name: route.path.includes('refuse')?'拒付黑名单':'退款黑名单',
          ...logParams,
        })
        this.customerGroupId = customer_group_id
        return this.customerGroupId
      } catch (error) {
        console.error(error)
      }
    }
  }
});