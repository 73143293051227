import request from '@/api/request'
import type { OperationLogApiData } from '@/types/interface/operationRecord'

const Api = {
  Upload: '/api/v2/merchant/tool/image/upload',
  Currencies: '/api/v2/base/countries/currency/pairs',
  Currenciesv2: '/api/v2/base/countries/currency/list',
  ReducedCurrencies: '/api/v2/merchant/price-template/config/country2currency',
  // currencyDict: '/api/v2/store/tool/exchange-rate', // 旧接口
  currencyDict: '/api/v2/base/usd-exchange-rates', // 新接口
  newCountryList: '/api/v2/base/countries/all',
  payPlatform: '/api/v2/base/third-platforms',
}

//  上传到 google cdn
export const upload = (data: any) =>
  request.post(Api.Upload, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

//  货币列表-查询
export const getCurrencies = (params: any) =>
  request.get(Api.Currencies, { params })

//  货币列表v2-查询
export const getCurrenciesv2 = (params?: any) =>
  request.get(Api.Currenciesv2, { params })

//  精简国家货币列表-查询
export const getReducedCurrencies = (params?: any) =>
  request.get(Api.ReducedCurrencies, { params })

// 获取汇率
export const getCurrencyDict = async (params: any) => {
  const res = await request.get(Api.currencyDict, { params })
  return { data: res.conversion_rates }
}

// 全部国家查询
export const getCountryList = () => request.get(Api.newCountryList)

// 支付渠道
export const getPayPlatform = () => request.get(Api.payPlatform)

export const operationLogApi = (data: OperationLogApiData) =>
  request.post('/api/v2/merchant/operlog/create', data)
