// @ts-nocheck
import { Modal } from 'ant-design-vue';
import ImIcon from "@/components/common/ImIcon.vue";
import { h } from 'vue';

function getIcon(iconType = '') {
  if (iconType === 'warning') {
    return 'icon-warning';
  } else if (iconType === 'success') {
    return `icon-success`;
  } else if (iconType === 'info') {
    return `icon-info`;
  } else {
    return `icon-error`;
  }
}

function renderContent({ content }, iconType) {
  if (typeof content === 'string') {
      return h('div', {}, [
        h(ImIcon, {
          props: {
            icon: getIcon(iconType)
          },
          style: {
            marginRight: '9px'
          }
        }),
        h('span', {}, content)
      ])
    // <div innerHTML={`<div>${content}</div>`}></div>;
  } else {
    return content;
  }
}
function renderTitle({ title }) {
  return title;
}

const getBaseOptions = (ctx) => {
  const vm = ctx
  return {
    cancelText: '取消',
    okText: '确认',
    centered: true,
    class: 'im-modal',
    okButtonProps: {
      props: {
        size: 'small'
      },
      class: 'im-button im-button-small'
    }, cancelButtonProps: {
      props: {
        size: 'small'
      },
      class: 'im-button im-button-small'
    },
  };
};

function createModalOptions(options = {}) {
  return {
    ...getBaseOptions(),
    ...options,
    content: renderContent(options),
  };
}

/**
 * @description: Create confirmation box
 */

export const createConfirm = function (options) {
  const iconType = options.icon || 'warning';
  const opt = {
    zIndex: 2002,
    icon: () => { return null },
    ...getBaseOptions(this),
    ...options,
    closable: true,
    class: 'im-model-confirm__normal',
    content: renderContent(options, iconType),
    title: renderTitle(options),
  };
  return Modal.confirm(opt);
}

export const createSuccessModal = function (options) {
  return Modal.success(createModalOptions(options, 'success'));
}

export const createErrorModal = function (options) {
  return Modal.error(createModalOptions(options, 'close'));
}

export const createInfoModal = function (options) {
  return Modal.info(createModalOptions(options, 'info'));
}

export const createWarningModal = function (options) {
  return Modal.warning(createModalOptions(options, 'warning'));
}
