import { getPrmUsersSideMenuApi } from "@/api/permissionsApi";
import type { UserInfoType } from "@/types/interface/userInterface";
import { defineStore } from "pinia"
import cookies from 'js-cookie'
import { TokenName } from '@/config/cookieKeys';
import { getUserInfo } from "@/api/user";
import type { AuthItem } from "@/types/interface/auth";
import type { RouteRecordRaw } from "vue-router";
import type { BaseObject } from "@/types/interface/base";
import { asyncTopRoutesList } from "@/router/list/top";
import { asyncProjectRoutesList } from "@/router/list/project";
import { cloneDeep } from "lodash-es";

function filterMenuData(list: RouteRecordRaw[], permissionMenuNameMap: BaseObject<boolean>): RouteRecordRaw[] {
  if (!list?.length) {
    return []
  }
  const m = list.map(r => {
    if (r) {
      let res: null | RouteRecordRaw = null
      if (r.meta?.isHideChildren && permissionMenuNameMap[r.name as string]) {
        return r
      }
      if (r.meta?.isNotSelect) {
        res = r
      }
      if (permissionMenuNameMap[r.name as string]) {
        res = r;
      }
      r.children = r.children
        ? filterMenuData(r.children, permissionMenuNameMap)
        : []
      if (r.children?.length) {
        res = r;
      }
      return res;
    }
    return null;
  })
  return m.filter(Boolean) as RouteRecordRaw[]
}

type userType = UserInfoType | null;

interface UserStoreState {
  user: userType
  userNo: string
  roleNo: string
  menuData: AuthItem[]
  projectData: AuthItem[]
  topRouter: RouteRecordRaw[]
  projectRouter: RouteRecordRaw[]
  allPermissionRouter: RouteRecordRaw[]
  asyncTopRoutesListClone: RouteRecordRaw[]
  asyncProjectRoutesListClone: RouteRecordRaw[]
}

export const UserStore = defineStore('user', {
  state: (): UserStoreState => ({
    user: null as userType,
    userNo: '',
    roleNo: '',
    menuData: [] as AuthItem[],
    projectData: [] as AuthItem[],
    topRouter: [] as RouteRecordRaw[],
    projectRouter: [] as RouteRecordRaw[],
    allPermissionRouter: [] as RouteRecordRaw[],
    asyncTopRoutesListClone: cloneDeep(asyncTopRoutesList),
    asyncProjectRoutesListClone: cloneDeep(asyncProjectRoutesList)
  }),

  getters: {
    permissionMenuNameMap(state): BaseObject<boolean> {
      return state.menuData.reduce((a, v) => ({
        ...a,
        [v.name]: true,
        ...v.children.reduce((ca, cv) => ({
          ...ca,
          [cv.name]: true
        }), {} as BaseObject<boolean>)
      }), {} as BaseObject<boolean>)
    }
  },

  actions: {
    SET_USER(user: userType) {
      this.user = user
      this.userNo = user?.user_no || ''
      this.roleNo = user?.role_no || ''
    },

    SET_CURRENT_MERCHANT_NO(payload: string) {
      if (this.user) {
        this.user.current_merchant_no = payload
      }
    },

    async getPrmUsersSideMenu() {
      try {
        const { menu } = await getPrmUsersSideMenuApi()
        this.menuData = menu.filter((m: AuthItem) => m.menu_type === 'M')
        this.projectData = menu.filter((m: AuthItem) => m.menu_type === 'P')
        this.topRouter = filterMenuData(this.asyncTopRoutesListClone, this.permissionMenuNameMap)
        this.projectRouter = filterMenuData(this.asyncProjectRoutesListClone, this.permissionMenuNameMap)
        this.allPermissionRouter = [
          ...this.topRouter,
          ...this.projectRouter,
        ]
        return {
          topRouter: this.topRouter,
          projectRouter: this.projectRouter,
          allPermissionRouter: this.allPermissionRouter,
          projectData: this.projectData,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getUserInfoMenu(): Promise<[UserInfoType, RouteRecordRaw[], AuthItem[]]> {
      try {
        if (this.user && this.allPermissionRouter?.length) {
          return [this.user, this.allPermissionRouter, this.projectData]
        }
        const cookiesToken = cookies.get(TokenName)
        const [userInfo, { allPermissionRouter, projectData }] = await Promise.all([
          getUserInfo(cookiesToken),
          this.getPrmUsersSideMenu()
        ])
        this.SET_USER(userInfo)
        return [userInfo, allPermissionRouter, projectData]
      } catch (error) {
        return Promise.reject(error)
      }
    },
  }
});
