import request from '@/api/request'
import type { CreatePrmRoleParams, EditPrmRoleParams, EditPrmUserParams, PrmRoleNoParams, PrmRolesParams, PrmUsersNoParams, PrmUsersParams } from '@/types/interface/auth'

/**
 * 获取用户菜单
 *
 * @param params
 * @returns
 */
export const getPrmUsersSideMenuApi = () => {
  return request.get('/api/v2/merchant/menu')
}

/**
 * 获取权限列表
 *
 * @param params
 * @returns
 */
export const getPrmAuthMenuApi = () => {
  return request.get('/api/v2/merchant/perm/list')
}

/**
 * 获取权限页面的用户列表
 *
 * @param params
 * @returns
 */
export const getPrmUsersListApi = (params: PrmUsersParams) => {
  return request.get('/api/v2/merchant/user/list', { params })
}

/**
 * 编辑用户昵称
 * */
export const editNickNameApi = (data)=>{
  return request.post('/api/v2/merchant/user/edit', data)
}

/**
 * 删除权限页面的用户
 *
 * @param data 
 * @returns 
 */
export const deletePrmUserApi = (data: PrmUsersNoParams) => {
  return request.post('/api/v2/merchant/delete', data)
}

/**
 * 获取权限页面的角色列表
 *
 * @param params
 * @returns
 */
export const getPrmRolesListApi = (params: PrmRolesParams) => {
  return request.get('/api/v2/merchant/role/list', { params })
}

/**
 * 获取权限页面的角色详情
 *
 * @param params
 * @returns
 */
export const getPrmRoleInfoApi = (params: PrmRoleNoParams) => {
  return request.get('/api/v2/merchant/role/info', { params })
}

/**
 * 获取权限页面的用户权限
 *
 * @param params
 * @returns
 */
export const getPrmUserAuthApi = (params: PrmUsersNoParams) => {
  return request.get('/api/v2/merchant/perm/user/info', { params })
}

/**
 * 编辑权限页面的用户权限及角色
 *
 * @param data 
 * @returns 
 */
export const editPrmUserAuthApi = (data: EditPrmUserParams) => {
  return request.post('/api/v2/merchant/perm/user/edit', data)
}

/**
 * 创建权限页面的角色
 *
 * @param data 
 * @returns 
 */
export const createPrmRoleApi = (data: CreatePrmRoleParams) => {
  return request.post('/api/v2/merchant/role/create', data)
}

/**
 * 编辑权限页面的角色
 *
 * @param data 
 * @returns 
 */
export const editPrmRoleApi = (data: EditPrmRoleParams) => {
  return request.post('/api/v2/merchant/role/edit', data)
}

/**
 * 删除权限页面的角色
 *
 * @param data 
 * @returns 
 */
export const deletePrmRoleApi = (data: PrmRoleNoParams) => {
  return request.post('/api/v2/merchant/role/delete', data)
}

