// @ts-nocheck
import axios from 'axios'
import { HttpCodeEnum } from '@/types/enums/HttpCodeEnum'
import { SandboxModeKey } from '@/config/cookieKeys'
import { PageEnum } from '@/types/enums/pageEnum'
import { removeAuthInfo, getToken, setToken, loginOut } from '@/utils/utils'
import cookies from 'js-cookie'
import { message as $message } from 'ant-design-vue'

const sandboxApis = [
  '/merchant/store',
  '/merchant/pay',
  '/merchant/refund-data',
  '/merchant/data',
  '/bi/merchant',
  '/merchant/order',
  '/merchant/transaction-ship',
  '/bi/payment-active',
  '/merchant/biz',
  '/merchant/bi'
]

const readerData = (response: any) => {
  return new Promise((resolve, reject) => {
    let result
    const reader = new FileReader()
    const isTextPlain = response.data.type === 'text/plain'
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    reader.readAsText(blob, 'utf-8')
    reader.onload = function () {
      try {
        const isWorksheets = typeof reader?.result === 'string'
          && reader?.result?.includes?.('worksheets')
        result = isWorksheets || isTextPlain
          ? response
          : JSON.parse(reader?.result as string)
        resolve(result)
      } catch (error) {
        reject(error)
      }
    }
  })
}

const redirect = (url: string) => window.location.href = url

const instance = axios.create({
  baseURL: '',
  // 请求超时时间
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

// http 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (config.headers) {
      config.headers.Authorization = token;
    }
    // 解决浏览器GET方法缓存问题:时间戳
    if (config?.method?.toLocaleLowerCase?.() === 'get' && config?.params) {
      // eslint-disable-next-line no-param-reassign
      config.params = {
        t: +new Date(),
        ...config.params,
      };
    }

    const isSandbox = !!cookies.get(SandboxModeKey)

    // 沙盒模式且是api中的交易和订单接口
    if (isSandbox && sandboxApis.some(part => config?.url?.includes?.(part))) {
      if (config?.method?.toLocaleLowerCase?.() === 'get') {
        config.params = {
          ...config.params,
          sandbox: +isSandbox
        }
      } else if (Object.prototype.toString.call(config.data) === '[object Object]') {
        config.data = {
          ...config.data,
          sandbox: +isSandbox
        }
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)

// http 响应拦截器
instance.interceptors.response.use(async (response) => {

  // 是否为导出接口，流形式直接返回
  const isExport = response.config.params?.export === 1
  if (isExport) {
    return response
  }

  // blob 文件
  if (response.status === 201 || response.config.responseType === 'blob' || (response.headers['content-disposition'] && response.headers['content-disposition'].includes('attachment'))) {
    const result: any = await readerData(response)
    return result.code ? Promise.reject(result) : result
  }

  if (/^[4|5]/.test(response?.status?.toString?.())) {
    return Promise.reject({ code: response.status, message: response.statusText })
  }

  const resData = response.data
  const { code, message } = resData;
  let { data } = resData;
  if (code === HttpCodeEnum.ERROR_PERMISSION_DENIED || code === HttpCodeEnum.ERROR_SIGNATURE_EXPIRED || code === HttpCodeEnum.ERROR_NO_LOGIN || code === HttpCodeEnum.ERROR_API_PERMISSION_DENIED) {
    $message.error(`权限不足 code: ${code}`)

    // 必须清除token，因为是根据是否有token判断登录态
    const timer = setTimeout(() => {
      removeAuthInfo()
      redirect(PageEnum.BASE_LOGIN)
      clearTimeout(timer)
    }, 2000)
    return Promise.reject(resData)
  }

  // 刷新 token
  if (response.config.url?.includes?.('/merchant/change')) {
    const authorization = response.headers?.authorization;
    if (authorization) {
      if (data) {
        data.token = authorization;
      } else {
        data = {
          token: authorization
        }
      }
    }
  }

  //  获取token
  if (response?.config?.url?.includes?.('/login')) {
    const authorization = response.headers?.authorization;
    if (authorization) {
      data.token = authorization;
      setToken(authorization)
    }
  }

  // 这里逻辑可以根据项目进行修改
  if (HttpCodeEnum.INFO_SUCCESS === code) {
    return data;
  }

  // 在此处根据自己项目的实际情况对不同的code执行不同的操作
  // 如果不希望中断当前请求，请return数据，否则直接抛出异常即可
  // let timeoutMsg = '';
  switch (code) {
    case HttpCodeEnum.ERROR_NO_GALLERY:
      return Promise.reject({ code, message });
    case HttpCodeEnum.ERROR_SIGNATURE_EXPIRED:
      alert('code' + code)
      loginOut();
      break;
    default:
    // timeoutMsg = message
  }
  return Promise.reject({ code, message });
})

export default instance;